<template>
    <div class="Contact">
        <div class="contact-info">
            <h4>苏州复数智能科技有限公司</h4>
            <p>地址：<span>苏州市相城区元和街道嘉元路959号元和大厦415B</span></p>
            <p>邮编：<span>215131</span></p>
            <p>QQ：<span>2093982637</span></p>
            <p>邮箱：<span>fusongsoochow@163.com</span></p>
        </div>
        <!-- map -->
        <div style="width:80%">
            <baidu-map
                class="map"
                :center="{lng: 120.631217, lat: 31.375715}"
                :zoom="15"
            >
                <bm-marker
                    :position="{lng:120.631217, lat: 31.375715}"
                    :dragging="true"
                >
                    <bm-info-window
                        :show="show"
                    >
                        <p>苏州复数智能科技有限公司</p>
                        <p>地址：苏州市相城区元和街道嘉元路959号元和大厦415B</p>
                    </bm-info-window>
                </bm-marker>
            </baidu-map>
        </div>
        <!-- 留言 -->
        <div style="width:80%">
            <b-row
                class="leave-message"
                id="toLeaveMessage"
            >
                <h4>欢迎留言</h4>
                <b-col
                    cols="12"
                    lg="12"
                >
                    <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-width="100px"
                        class="demo-ruleForm"
                        label-position="top"
                    >
                        <!-- 姓名 -->
                        <el-form-item
                            prop="name"
                        >
                            <el-input
                                v-model="ruleForm.name"
                                placeholder="姓名"
                            />
                        </el-form-item>
                        <!-- 手机号 -->
                        <el-form-item
                            prop="phone_no"
                        >
                            <el-input
                                v-model="ruleForm.phone_no"
                                placeholder="手机号"
                            />
                        </el-form-item>
                        <!-- 邮箱 -->
                        <el-form-item
                            prop="email"
                        >
                            <el-input
                                v-model="ruleForm.email"
                                placeholder="邮箱"
                            />
                        </el-form-item>
                        <!-- 需求 -->
                        <el-form-item
                            prop="feedback"
                        >
                            <el-input
                                type="textarea"
                                v-model="ruleForm.feedback"
                                placeholder="请描述您的需求......"
                            />
                        </el-form-item>
                        <!-- 提交/重置 -->
                        <el-form-item>
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                            >
                                提交
                            </el-button>
                            <el-button @click="resetForm('ruleForm')">
                                重置
                            </el-button>
                        </el-form-item>
                    </el-form>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from '@/plugins/axios.js'
export default {
  name: 'Contact',
  data () {
        var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'))
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
          // console.log(reg.test(value))
          if (reg.test(value)) {
            callback()
          } else {
            return callback(new Error('请填写真实的手机号，便于我们与您取得联系。'))
          }
        }
      }
        return {
          show: true,
          ruleForm: {
            name: '',
            phone_no: '',
            email: '',
            feedback: '',
            csrftoken: ''
          },
          rules: {
            name: [
              { required: true, message: '请填写完整信息，便于我们理解您的需求，并与您取得联系。', trigger: 'blur' },
              { min: 2, max: 5, message: '长度在 2 到 5 个字符。', trigger: 'blur' }
            ],
            phone_no: [
              { trigger: 'blur', validator: checkPhone }
            ],
            email: [
                { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                { type: 'email', message: '邮箱地址格式不正确！', trigger: ['blur', 'change'] }
            ],
             feedback: [
              { required: true, message: '请填写完整信息，便于我们理解您的需求，并与您取得联系', trigger: 'blur' },
               { min: 15, message: '留言内容不得少于15个字符。', trigger: 'blur' },
               { max: 1000, message: '留言内容不得高于1000个字符。如需填写更多内容，您可以发送邮件至fusongsoochow@163.com。', trigger: 'blur' }
            ]
          }
        }
  },
  mounted () {
    const cookie = document.cookie.split(';')
    this.ruleForm.csrftoken = cookie[0].replace('csrftoken=', '')
    console.log(this.ruleForm.csrftoken)
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            axios.PostLeaveMessage(this.ruleForm).then(res => {
              console.log('res', res)
                if (res.status === 200) {
                  alert('提交成功！')
                } else {
                  alert('提交失败！')
                }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    parentEvent: function () {
      document.getElementById('toLeaveMessage').scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  },
  created () {
    this.$emit('contentTitle', '联系我们')
    this.$emit('isShowA', true)
  }
}
</script>

<style lang="less" scoped>
.Contact{
  margin-bottom:6.25rem;
  .contact-info{
    margin: 1.875rem 0;
    h4{
      font-size: 1.875rem;
      font-weight: 600;
      margin-bottom: 0.9375rem;
    }
    P{
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 1rem;
    }
  }
  .map{
    width: 100%;
    height: 400px;
  }
  .leave-message{
    margin-top: 6.25rem;
    h4{
      font-size: 1.875rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 0.125rem;
    }
    input,textarea{
      width: 100%;
      outline: none;
      border: none;
      background-color: #f2f2f2;
      margin: 0.625rem 0;
      border-radius: 9px;
    }
    input{
      height: 48px;
      padding: 0 1rem;
    }
    textarea{
      padding: 1rem;
    }
    button{
      width: fit-content;
      outline: none;
      border: none;
      background: #0074FF;
      color: #fff;
      padding: 0.3125rem 1.25rem;
      border-radius: 9px;
      font-size: 1rem;
    }
  }
  /deep/.el-textarea__inner{
    height: 99px;
  }
}
</style>
