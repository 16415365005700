import axios from 'axios'
// axios.defaults.baseURL = 'http://localhost:8000/'
axios.defaults.baseURL = 'https://www.fstwin.com/'

export default {

  PostLeaveMessage (data) {
    return axios.post('send_feedback/', data)
  }
}
